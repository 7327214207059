<template>
  <div class="right-bet">
    <div class="right-bet__carousel-control">
      <div class="right-bet__carousel-control__button" @click="onBeforeClicked">
        <img src="/icons/arrow_left.svg" alt="" />
      </div>
      <div>{{ currentBet }}/{{ totalBets.length }} Apuestas</div>
      <div class="right-bet__carousel-control__button" @click="onNextClicked">
        <img src="/icons/arrow_right.svg" alt="" />
      </div>
    </div>
    <BCarousel
      v-model="carouselIndex"
      :autoplay="false"
      :indicator-inside="false"
      :indicator="false"
      :arrow="false"
      @change="onCarouselChanged"
    >
      <BCarouselItem v-for="bet in totalBets" :key="bet.id">
        <div class="right-bet__item">
          <!--  bet info  -->
          <div v-if="isPremiumUser || bet.isFreemiumVisible" class="right-bet__item__bet-info">
            <div class="right-bet__item__bet-info__team-logo">
              <img
                :src="`${teamsLogosUrl}${bet.bet_suggested.home_team_logo || bet.bet_suggested.away_team_logo}`"
                alt=""
              />
            </div>
            <div class="right-bet__item__bet-info__result">
              <div class="right-bet__item__bet-info__result__text">
                {{ bet.bet_suggested.suggestion }}
              </div>
              <div class="right-bet__item__bet-info__result__prob">
                <div>Probabilidad de que suceda:</div>
                <div>{{ bet.prob_actual }}%</div>
              </div>
            </div>
          </div>
          <!--     create account     -->
          <template v-else>
            <div class="right-bet__item__create-account">
              <div class="right-bet__item__create-account__text">
                <div>{{ 'Solo para \nUsuarios Premium' }}</div>
                <div>$99 <span>al mes</span></div>
              </div>
              <div class="right-bet__item__create-account__button" @click="onCreateAccountClicked">
                Crear cuenta Premium
              </div>
            </div>
            <div class="right-bet__item__freemium-prob">
              <div>Probabilidad de que suceda:</div>
              <div>{{ bet.prob_actual }}%</div>
            </div>
          </template>
          <!--  match info  -->
          <div class="right-bet__item__match">
            <div class="right-bet__item__match__team">{{ bet.home_team_acronym }}</div>
            <div class="right-bet__item__match__logo">
              <img :src="`${teamsLogosUrl}${bet.home_team_logo}`" alt="" />
            </div>
            <div class="right-bet__item__match__vs">VS</div>
            <div class="right-bet__item__match__logo">
              <img :src="`${teamsLogosUrl}${bet.away_team_logo}`" alt="" />
            </div>
            <div class="right-bet__item__match__team">{{ bet.away_team_acronym }}</div>
          </div>
          <!--     round     -->
          <div v-if="isMatchDayVisible(bet)" class="right-bet__item__round">Jornada {{ bet.round }}</div>
        </div>
      </BCarouselItem>
    </BCarousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
import axios from 'axios';
import { freePicksSwipeLeft, freePicksSwipeRight, clickCrearCuentaPremium } from '@/utils/analytics';
import { mapMutations, mapGetters } from 'vuex';
const twoHorusInMillis = 1000 * 60 * 60 * 2;
const betSuggestions = {
  1: 'Gana',
  2: 'Empate',
  3: 'Gana/Empata',
  4: 'Gana/Gana',
};

export default {
  name: 'RightBet',
  components: {
    BCarousel,
    BCarouselItem,
  },
  props: {
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselIndex: 0,
      totalBets: [],
      teamsLogosUrl: 'https://az755631.vo.msecnd.net/teams-80/',
      swipe: '',
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats', 'getSectionAnalytics']),
    ...mapGetters('general', ['getSectionAnalytics']),
    currentBet() {
      return this.totalBets.length ? this.carouselIndex + 1 : 0;
    },
  },
  watch: {
    isPremiumUser() {
      this.fillBets();
    },
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('general', ['setSectionAnalytics']),
    async fetchBets() {
      try {
        const response = await axios.get('https://eg4vmknope.execute-api.us-east-2.amazonaws.com/prod/right_bet/');
        return response.data.tournaments || [];
      } catch (e) {
        throw new Error(e);
      }
    },
    async fillBets() {
      try {
        const limitTime = new Date().valueOf() + twoHorusInMillis;
        const betsByTournament = await this.fetchBets();
        let league = betsByTournament.find(tournament => tournament.tournament_id === 1);
        if (this.isLeagueEmpty(league)) {
          league = betsByTournament.find(tournament => tournament.tournament_id !== 1);
        }
        if (this.isLeagueEmpty(league)) {
          return;
        }
        const betsByRounds = Array.from(
          league.bets
            .reduce((accumulator, bet) => {
              bet.dateTimeMillis = new Date(bet.date).valueOf();
              bet.bet_suggested.suggestion = betSuggestions[bet.bet_subtype_id];
              if (bet.dateTimeMillis > limitTime) {
                let round = accumulator.get(bet.matchday);
                if (round) {
                  round.bets.push(bet);
                } else {
                  round = {
                    matchDay: bet.matchday,
                    bets: [bet],
                  };
                  accumulator.set(bet.matchday, round);
                }
              }
              return accumulator;
            }, new Map())
            .values(),
        );
        betsByRounds.sort((a, b) => {
          return a.matchDay - b.matchDay;
        });
        betsByRounds.forEach(betsByRound => {
          betsByRound.bets.sort((a, b) => {
            return a.dateTimeMillis - b.dateTimeMillis;
          });
        });
        this.totalBets = this.getSortedBets(betsByRounds, this.isPremiumUser);
      } catch (e) {
        throw new Error(e);
      }
    },
    getSortedBets(betsByRounds, isPremiumUser) {
      return betsByRounds.reduce((accumulator, betsByRound) => {
        if (!isPremiumUser) {
          const lastBet = betsByRound.bets.pop();
          if (lastBet) {
            lastBet.isFreemiumVisible = true;
            betsByRound.bets.unshift(lastBet);
          }
        }
        accumulator.push(...betsByRound.bets);
        return accumulator;
      }, []);
    },
    isLeagueEmpty(league) {
      return !league || !league.bets || !league.bets.length;
    },
    onNextClicked() {
      this.swipe = 'right';
      if (this.carouselIndex === this.totalBets.length - 1) {
        this.carouselIndex = 0;
      } else {
        this.carouselIndex++;
      }
    },
    onBeforeClicked() {
      this.swipe = 'left';
      if (this.carouselIndex === 0) {
        this.carouselIndex = this.totalBets.length - 1;
      } else {
        this.carouselIndex--;
      }
    },
    onCarouselChanged($event) {
      if (this.carouselIndex === 0 && $event === this.totalBets.length - 1) {
        freePicksSwipeLeft({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      } else if ($event === 0 && this.carouselIndex === this.totalBets.length - 1) {
        freePicksSwipeRight({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      } else if ($event > this.carouselIndex) {
        freePicksSwipeRight({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      } else if ($event < this.carouselIndex) {
        freePicksSwipeLeft({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      } else if ($event === this.carouselIndex && this.swipe === 'right') {
        freePicksSwipeRight({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      } else if ($event === this.carouselIndex && this.swipe === 'left') {
        freePicksSwipeLeft({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      }
      if ($event !== this.carouselIndex) {
        this.carouselIndex = $event;
      }
    },
    onCreateAccountClicked() {
      this.setSectionAnalytics('FP_AD');
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        section: 'free picks - apuesta derecha',
        golstats: this.getIsGolstats,
        banner: this.getSectionAnalytics,
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
    isMatchDayVisible(bet) {
      const round = bet.matchday_name ? bet.matchday_name.split(' ').pop() : null;
      const isRegularSeason = bet.matchday_abbreviation.toLowerCase().includes('m');
      bet.round = round;
      return round && (bet.tournament_id !== 1 || isRegularSeason);
    },
  },
  async beforeMount() {
    this.fillBets();
  },
};
</script>

<style scoped lang="scss">
.right-bet {
  width: 100%;

  &__carousel-control {
    width: 100%;
    display: flex;
    justify-content: center;
    line-height: 1.29;
    letter-spacing: -0.82px;
    color: #a5a5a5;
    font-family: Circular-Std-Medium, sans-serif;
    margin-top: 0.6rem;

    &__button {
      margin: 0 0.6rem;
      cursor: pointer;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__match {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #242424;

      &__team {
        font-family: Avenir-Pro-Bold, sans-serif;
        font-size: 0.87em;
        text-transform: uppercase;
        letter-spacing: -0.26px;
      }

      &__logo {
        height: 32px;
        width: 32px;

        & > img {
          object-fit: contain;
        }
      }

      &__vs {
        font-family: Avenir-Demi, sans-serif;
      }

      & > div {
        margin: 0 0.37em;
      }
    }

    &__bet-info {
      margin: 3em 0 2.1em;
      width: 100%;
      display: grid;
      grid-template-columns: 46% 54%;

      &__team-logo {
        height: 4.4em;
        width: 4.4em;
        justify-self: right;
        margin-right: 1.12em;
        border-radius: 50%;
        box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.75);

        & > img {
          object-fit: contain;
        }
      }

      &__result {
        margin-top: 0.2em;
        &__text {
          width: fit-content;
          padding: 0.1em 0.4em 0;
          font-family: Avenir-Pro-Bold, sans-serif;
          font-size: 1.87em;
          color: white;
          background-color: #7ed321;
        }

        &__prob {
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;

          & > div {
            margin: 0.2em;
            &:first-child {
              font-family: Circular-Std-Book, sans-serif;
              line-height: 1.51;
              letter-spacing: -0.66px;
              color: #717b89;
            }

            &:last-child {
              padding: 0.5em 0.3em;
              color: white;
              font-family: Circular-Std-Bold, sans-serif;
              border-radius: 50%;
              background: #7ed321;
            }
          }
        }
      }

      @media screen and (max-width: 485px) {
        margin: 2.2em 0 1.5em;
        display: flex;

        justify-content: center;
      }

      @media screen and (max-width: 340px) {
        font-size: 0.9rem !important;
      }
    }

    &__round {
      font-family: Avenir-Regular, sans-serif;
      font-weight: 500;
      color: #606060;
      line-height: 1.89;
      font-size: 0.87em;
      margin-bottom: 1em;
    }

    &__create-account {
      margin-top: 0.4em;
      padding: 1.2em 2.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 18.9px;
      background-color: #333;
      color: white;
      font-family: Circular-Std-Bold, sans-serif;

      &__text {
        margin-right: 1.5em;
        width: fit-content;

        > div {
          &:first-child {
            font-family: Circular-Std-Medium, sans-serif;
            font-size: 1.25em;
            line-height: 1.05;
            letter-spacing: -0.65px;
            //white-space: pre;
          }

          &:last-child {
            font-size: 2.3em;
            color: #c9e3b6;
            line-height: 0.85;
            margin-top: 0.2em;

            & > span {
              font-size: 0.72em;
            }
          }
        }
      }

      &__button {
        margin-left: 1.5em;
        border-radius: 10px;
        border: solid 2px #c9e3b6;
        cursor: pointer;
        font-size: 1em;
        line-height: 2.12;
        letter-spacing: -0.52px;
        padding: 0 1em;
      }

      @media screen and (max-width: 580px) {
        &__text {
          > div {
            &:first-child {
              white-space: pre;
            }
          }
        }
      }

      @media screen and (max-width: 485px) {
        font-size: 0.9rem !important;
      }

      @media screen and (max-width: 439px) {
        font-size: 0.8rem !important;
      }

      @media screen and (max-width: 387px) {
        font-size: 0.7rem !important;
      }

      @media screen and (max-width: 335px) {
        font-size: 0.6rem !important;
      }
    }

    &__freemium-prob {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.72em;

      & > div {
        margin: 0.2em;
        &:first-child {
          font-family: Circular-Std-Book, sans-serif;
          line-height: 1.51;
          letter-spacing: -0.66px;
          color: #b9bbbd;
        }

        &:last-child {
          padding: 0.5em 0.3em;
          color: white;
          font-family: Circular-Std-Bold, sans-serif;
          border-radius: 50%;
          background: #7ed321;
        }
      }
    }
  }
}
</style>
